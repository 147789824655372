<template>
  <div
    class="container mx-auto px-5 py-3 border-b-2 border-black mb-6 flex flex-wrap"
    :class="additionalWrapperClasses"
    role="region"
    :aria-labelledby="buttonID"
  >
    <button
      :id="buttonID"
      type="button"
      class="hover:text-gray-500 flex flex-row justify-between min-w-full uppercase w-40 tracking-wider my-auto text-xs font-semibold mr-2"
      @click="toggle"
      :aria-expanded="expanded"
      :aria-controls="contentID"
    >
      <span>{{ title }}</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6 transform transition-transform"
        :class="{ 'rotate-90': expanded }"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M9 5l7 7-7 7"
        />
      </svg>
    </button>
    <div
      :class="[twDuration, { 'max-h-0': !expanded, 'max-h-screen': expanded }]"
      ref="expandableContent"
      :id="contentID"
      :aria-hidden="!expanded"
      class="font-bold overflow-hidden transition-all ease-in-out"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { camelize } from 'vue';

export default {
  name: 'AccordionPanel',
  props: {
    title: {
      type: String,
      required: true,
    },
    twDuration: {
      // Tailwind CSS duration class, `duration-<number>`. Number is ms and built-in values are 0, 75, 100, 150, 200, 300, 500, 700, 1000
      type: String,
      default: 'duration-300',
    },
    additionalWrapperClasses: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has(this.paramName)) {
      this.expanded = urlParams.get(this.paramName) === 'true';
      // If panel is expanded at load, then we need to additionally set max-height to actual height
      // so that the css transition works as expected.
      // Otherwise, it will begin from the default max-height, which is the screen height.
      // that introduces a perceived delay in the transition.
      this.$nextTick(() => {
        this.setMaxHeight();
      });
    }
  },
  computed: {
    // camelize() only works on kabob-case strings
    paramName() {
      return camelize(this.title.replace(' ', '-').toLowerCase());
    },
    buttonID() {
      return camelize(`${this.title.replace(' ', '-').toLowerCase()}Button`);
    },
    contentID() {
      return camelize(`${this.title.replace(' ', '-').toLowerCase()}Content`);
    },
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded;
      this.setMaxHeight();
      this.setExpandedURLState();
    },
    setMaxHeight() {
      const content = this.$refs.expandableContent;
      if (this.expanded) {
        content.style.maxHeight = `${content.scrollHeight}px`;
      } else {
        content.style.maxHeight = '0';
      }
    },
    setExpandedURLState() {
      let urlParams = new URLSearchParams(window.location.search);

      if (this.expanded) {
        urlParams.set(this.paramName, 'true');
      } else {
        urlParams.delete(this.paramName);
      }

      history.pushState(
        {},
        null,
        `${this.$route.path}?${urlParams.toString()}`,
      );
    },
  },
};
</script>
